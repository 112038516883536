import '@shopify/polaris/build/esm/styles.css'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from '@shopify/app-bridge-react'

function importBuildTarget() {
	if (process.env.REACT_APP_BUILD_TARGET === 'main') {
		return import('./apps/main')
	} else if (process.env.REACT_APP_BUILD_TARGET === 'connecting') {
		return import('./apps/connecting')
	} else if (process.env.REACT_APP_BUILD_TARGET === 'authorizing') {
		return import('./apps/authorizing')
	} else {
		return Promise.reject(
			new Error('No such build target: ' + process.env.REACT_APP_BUILD_TARGET)
		)
	}
}

// Import the entry point and render it's default export
importBuildTarget().then(({ default: Environment }) => {
	const root = createRoot(document.getElementById('root'))
	const host = new URLSearchParams(window.location.search).get('host')

	const config = {
		apiKey: '33f8546ba49863979b7fc2abc3756ba1',
		host: host ? host : 'YWRtaW4uc2hvcGlmeS5jb20=', // admin.shopify.com
		forceRedirect: false,
	}

	root.render(
		<Provider config={config}>
			<React.StrictMode>
				<Environment />
			</React.StrictMode>
		</Provider>
	)
})
